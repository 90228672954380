.upload-progress-area {
    position: fixed;
    bottom: 0;
    right: 3em;

    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    border: 1px solid gray;
    border-bottom: none;
    box-shadow: 10px 10px 30px transparentize($color: black, $amount: 0.8);

    width: 300px;
    height: 400px;
    background: white;
    overflow-y: auto;
    overflow-x: clip;

    * {
        width: 100%;
    }

    h3 {
        padding: 5px;
        border-bottom: 1px solid gray;
        margin-bottom: 0;
    }

    transition: transform 0.3s;

    &:not(.visible) {
        transform: translateY(calc(100% + 5px));
    }

    .progress-item-list {
        display: grid;
        grid-template-columns: 100%;
        grid-auto-rows: auto;
        grid-auto-flow: row;
        gap: 5px;
        padding: 3px;
        background: rgb(206, 206, 206);
        width: 100%;
    }

    .progress-item {
        border: 1px solid gray;
        border-radius: 3px;
        padding: 3px;
        background: white;
        width: 100%;
    }

    .progress-item-name {
        max-width: 100%;
        text-wrap: nowrap;
        text-overflow: ellipsis;
        overflow: clip;
        white-space: nowrap;
        font-size: 120%;
    }

    .progress-item-phase {
        text-wrap: nowrap;
        text-overflow: ellipsis;
        overflow: clip;
        white-space: nowrap;
    }
}