.share-ui {
    display: grid;
    place-items: center;
    gap: 1em;

    .card {
        .card-header {
            display: grid;
            grid-template-columns: 1fr;
            grid-auto-flow: column;
            grid-auto-columns: auto;
            place-items: center;
            gap: .5em;
        }
    }

    .share-description {
        margin-bottom: .5em;
    }

    .share-ui-buttons {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: auto;
        gap: 1em;
    }
}

.share-edit {
    .modal-body {
        display: grid;
        gap: .5em;
    }

    .badge {
        margin-left: 0.5em;
    }

    .event {
        border: 1px dotted gray;
        margin-left: 0.5em;
        display: inline-block;
        padding: 0 5px;
    }
}

.popover.event,
.popover.tags,
.popover.exclude_tags {
    max-height: 40svh;
    overflow: scroll;
}