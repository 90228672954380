.login-page {
    display: grid;
    place-content: center;
    place-items: center;
    min-height: 100dvh;

    gap: .5em;

    .btn {
        justify-self: stretch;
    }
}