.dropzone {
    min-height: 100dvh;

    &.isOver::before {
        margin: 10px;
        padding: 10px;
        border: 3px dashed black;
        border-radius: 20px;
        display: grid;
        place-content: center;
        background: hsla(0deg, 0%, 100%, 30%);
        content: 'Drop to upload';

        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}