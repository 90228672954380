body:has(.preview-screen) {
    overflow: hidden;
}

.preview-screen {

    position: fixed;
    top: 0;
    left: -100vw;
    bottom: 0;
    right: -100vw;
    backdrop-filter: brightness(20%) grayscale(70%) blur(5px);
    width: 300vw;

    display: grid;
    grid-template-columns: repeat(3, 1fr);

}

.preview {
    width: 100vw;
    height: 100dvh;
    overflow: clip;

    &>* {
        z-index: 101;
    }

    color: white;

    display: grid;
    place-items: center;
    grid: "img   meta" 1fr //
    "  title meta" auto / 1fr auto;

    z-index: 100;

    .img-stack-container {
        container-type: size;
        width: 100%;
        height: 100%;
        min-width: 0;
        min-height: 0;

        display: grid;
        grid: "img" 1fr / 1fr;
        place-content: center;
        place-items: center;

        pointer-events: none;


        &>* {
            pointer-events: all;
        }
    }

    .img-stack {
        width: min(var(--preview-width), calc(100cqh * var(--aspect-ratio)), 100cqw);
        height: min(var(--preview-height), calc(100cqw / var(--aspect-ratio)), 100cqh);

        display: grid;
        place-content: center;
        place-items: center;

        grid: "img" 1fr / 1fr;

        &>* {
            grid-area: img;
            width: 100%;
            height: 100%;
        }

        &[data-zoom] {
            cursor: grab;

            &:active {
                cursor: grabbing;
            }
        }
    }

    .meta {
        grid-area: meta;
        height: 100%;
        display: grid;
        grid-template-rows: auto 1fr;

        transition: opacity linear 0.05s;
        transition: max-width linear 0.05s;
        max-width: 30vw;
        opacity: 1;

        &:not(.visible) {
            max-width: 0;
            opacity: 0;
        }

        .inner {
            max-height: 100%;
            overflow: scroll;
            padding: 1em;
        }
    }

    .title {
        grid-area: title;
        text-align: center;
        width: 100%;
        min-width: 0;
        max-width: 500px;


        .preview-title-row {
            max-width: 100%;
            display: flex;
            flex-direction: row;
            gap: .5em;

            h3 {
                flex-grow: 1;
                min-width: 0;

                text-wrap: nowrap;
                text-overflow: ellipsis;
                overflow: clip;
            }
        }

        .preview-meta-row {
            display: flex;
            gap: 1em;
            align-content: stretch;
            justify-content: space-between;
        }


        svg {
            cursor: pointer;
        }

        @media (max-height: 500px) {
            grid-area: img;
            align-self: end;
            background: #0008;
            box-shadow: 0 0 20px black;
            padding: 3px;
        }
    }
}

.clickable-text {
    cursor: pointer;
    text-decoration: underline;
}