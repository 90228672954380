.overview {
    user-select: none;

    .group {
        padding: 16px;

        h2 a {
            color: unset;
            text-decoration: unset;
        }
    }
}

.files {
    display: grid;
    grid-template: "stuff" auto / auto;
    position: relative;
    z-index: 0;

    contain: paint layout inline-size;


    &>div {
        display: flex;
        flex-wrap: wrap;
        grid-area: "stuff";
    }

    &>.thumbnails-placeholders {
        z-index: 10;
    }

    &>.thumbnails-actual {
        z-index: 20;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        min-width: 0;
        min-height: 0;
    }



    .thumbnail,
    .thumbnails-placeholders>* {
        width: 64px;
        height: 64px;
        position: relative;
    }

    .thumbnail {
        img {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;

            width: 64px;
            height: 64px;
            object-fit: cover;
        }

        overflow: clip;

        .progress {
            background: white;
            position: absolute;
            height: 4px;
            bottom: 0px;
            width: calc(100% * attr("data-progress"));
            outline: 1px solid black;
        }

        &.thumbnail-waiting img {
            filter: grayscale(100%) opacity(50%);
        }

        &.selected {
            position: relative;

            &::after {
                /* This is the (blue) selection overlay */
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;

                opacity: 30%;
                background-color: blue;
            }
        }
    }
}