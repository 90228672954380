.toolbar {
    border-left: 1px solid black;
    margin-left: 1em;
    background: white;

    .toolbar-inner {
        position: sticky;
        top: 0;
        bottom: 0;
        height: 100dvh;
        overflow: auto;

        display: flex;
        flex-direction: column;
        gap: 1em;
        padding: 1em;
    }

    .selection-tools {
        display: contents;
    }

    @media (max-width: 600px) {
        position: sticky;
        top: 0;
        border-left: none;
        margin-left: 0;
        border-bottom: 1px solid black;
        background: transparentize($color: white, $amount: 0.1);
        width: 100vw;

        .toolbar-inner {
            height: 100%;
            padding: 0.5em;

            display: flex;
            flex-direction: row;
            place-items: center;
        }

        .selection-info {
            flex-grow: 1;
        }

        .icon-button {
            span {
                display: none; // hide the text of the icon buttons
            }
        }
    }

    @media (max-width: 600px) {
        .desktop {
            display: none;
        }

        .mobile {
            display: unset;
        }
    }

    .mobile {
        display: none;
    }
}