body {
    margin: 0;
}

#App {
    height: 100dvh;
    margin: 0;
}

.main-content {
    display: grid;
    grid: 'overview toolbar' auto / 1fr max(30vw, 300px);

    @media (max-width: 600px) {
        grid: 'toolbar' 120px 'overview' 1fr / auto;
    }
}

.toolbar {
    grid-area: toolbar;
}

.overview {
    grid-area: overview;
}